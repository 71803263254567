<template>
    <div>
      <BaseNav></BaseNav>
      <!-- Page content -->
      <div class="page-content">
        <!-- Main content -->
        <div class="content-wrapper">
          <!-- Content area -->
          <div class="content d-flex justify-content-center align-items-center">
            <!-- Login card -->
            <form
              class="verify-form"
              ref="verify-form"
              @submit.prevent="submitLogin"
            >
              <div class="card mb-0 shadow-lg rounded">
                <div class="card-body py-4">
                  <div class="text-center  mb-3">
                    <!-- <i
                      class="
                        icon-reading icon-2x
                        text-slate-300
                        border-slate-300 border-3
                        rounded-round
                        p-3
                        mb-3
                        mt-1
                      "
                    ></i> -->
                    <h5 class="mb-0 py-1 text-uppercase">Enter Verification code</h5>
                    <span>You should receive a verification code on the registered email</span>
                    <!-- <div class="alert alert-success">
                        Client: client@academiawebsites.com <br/>
                        Password: demo
                    </div> -->
                  </div>
  
                  <!-- <div
                    class="
                      form-group form-group-feedback form-group-feedback-left
                    "
                  >
                    <input
                      type="text"
                      v-model="loginForm.email"
                      name="email"
                      required
                      class="form-control"
                      placeholder="Username"
                    />
                    <div class="form-control-feedback">
                      <i class="icon-user text-muted"></i>
                    </div>
                  </div> -->

                    <div class="py-3"  style="align-items: center;justify-content: center;display: flex;">
                        <v-otp-input
                        ref="otpInput"
                        input-classes="otp-input"
                        separator="-"
                        
                        :num-inputs="4"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                        />
                    </div>

  
                  <div v-if="isLoading" style="align-items: center;justify-content: center;display: flex;" class=" py-1">
                    <svg version="1.1" id="Layer_1" xmlns="https://www.w3.org/2000/svg" xmlns:xlink="https://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" height="55px" viewBox="0 0 24 30" xml:space="preserve"><rect x="0" y="13" width="4" height="5" fill="#333"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="10" y="13" width="4" height="5" fill="#333"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="20" y="13" width="4" height="5" fill="#333"><animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg>
                  </div> 
                  
  
                  <div class="form-group mt-2 text-center text-muted content-divider">
                    <span class="px-2">Don't have an account?</span>
                  </div>
  
                  <div class="form-group">
                    <router-link
                      :to="{ name: 'Register' }"
                      class="btn btn-light btn-block"
                      >Sign up</router-link
                    >
                  </div>
  
                  <span class="form-text text-center text-muted"
                    >By continuing, you're confirming that you've read our
                    <a href="https://essayforall.org/terms-and-conditions/">Terms &amp; Conditions</a> and
                    <a href="https://essayforall.org/privacy-policy/">Privacy Policy</a></span
                  >
                </div>
              </div>
            </form>
            <!-- /login card -->
          </div>
          <!-- /content area -->
        </div>
        <!-- /main content -->
      </div>
      <!-- /page content -->
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from "vuex";
  export default {
    computed: {
      ...mapState(["auth/is_logged_in", "auth/user"]),
    },
    data() {
      return {
        isLoading:false,
        loginForm: new this.$Form({
          code: "",
          token: this.$route.query.verifyToken || '',
        }),
      };
    }, 
    methods: {
      ...mapActions("auth", ["doLogin", "doValidateLogin", "doGetAuthUser"]),

       handleOnComplete(value) {
        this.isLoading = true
        this.loginForm.code = value || ''
        // this.loginForm.token = this.$route.query.verifyToken || ''
        console.log('this.accessCode', this.accessCode);
          this.doValidateLogin(this.loginForm)
            .then((res) => {
              console.log('this is the login res===>', res)
              this.isLoading = false

              document.location.href = `/home/`;
            }) 
            .catch((err) => {
              this.isLoading = false

              this.$notify({
                title: "Error",
                text: err.response.data ? err.response.data.Message : "Error",
                style: "danger",
              });
            });


       },
       handleOnChange(value) {
        this.accessCode = value || ''

       },
       handleClearInput() {
        this.accessCode = ''

        this.$refs.otpInput.clearInput();
       },


      submitLogin() {
        let grecaptcha
        if (process.env === "production") {
          grecaptcha = window.grecaptcha.getResponse();
        } else {
          grecaptcha = "fasdflk";
        }
        if (!grecaptcha || grecaptcha == "") {
          this.$notify({
            title: "Error",
            text: "Please verify you are not a robot",
            style: "danger",
          });
        } else {
          this.loginForm.token = grecaptcha;
          this.doLogin(this.loginForm)
            .then(() => {
              if (this.$route.query.redirectAfterAuth) {
                document.location.href = this.$route.query.redirectAfterAuth;
              } else {
                document.location.href = `/${process.env.VUE_APP_INSTALL_DIR}/home/`;
              }
            })
            .catch((err) => {
              this.$notify({
                title: "Error",
                text: err.response.data ? err.response.data.Message : "Error",
                style: "danger",
              });
            });
        }
      },
    },
    mounted() {
      this.$parent.$emit("loader:hide");
      if (!this.$route.query.verifyToken) {
        document.location.href = `/login/`;
      } 
    },
  };
  </script>
